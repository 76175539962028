import React, { useContext } from 'react'
import GlobalContext from '../../contexts/globalContext'
import styles from '../../styles/cartPayment.module.css'

const CartPayment = (id) => {

        const { payPurchaseOrder, setStatePay } = useContext(GlobalContext)

        return (
                <article className={styles.container_cart_pay}>
                        <div className={styles.box_credit_cart}>
                                <h3>Distribuidora Aguadelzar</h3>
                                <h3>Banco Santander</h3>
                                <h3>Rut : 76.079.094-K</h3>
                                <h3>Cta corriente: 6331889-2</h3>
                                <button type='button' onClick={() => setStatePay(false)}>X</button>
                                <div>
                                        <form onSubmit={payPurchaseOrder}>
                                                <label>Subir comprobante de pago</label>
                                                <input type='file' name='voucher_image' accept='image/jpeg,image/png,image/jpg' />
                                                <button>ENVIAR</button>
                                        </form>
                                </div>
                        </div>
                </article>
        )
}

export default CartPayment