import {  API_LOC, HEADERS } from "./globals";
                
export const POST = ({
        table = null,
        data = null
}) =>
        fetch(`${API_LOC}/${table}`, {
                method: 'POST',
                headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                },
                body: data,
                redirect: 'follow',
        })
                .then(response => response.json())
                .catch(error => console.log('error', error));


export const POST_LOC = ({
        table = null,
        data = null
}) =>
        fetch(`${API_LOC}/${table}`, {
                method: 'POST',
                body: data,
                redirect: 'follow',
        })
                .then(response => response.json())
                .catch(error => console.log('error', error));

export const POST_UPLOAD = ({
        table = null,
        data = null,
        params = null,
}) =>
        fetch(`${API_LOC}/${table}${params}`, {
                method: 'POST',
                body: data,
                headers: HEADERS
        })
                .then(response => response.json())
                .catch(error => console.log('error', error));
