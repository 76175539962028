import * as React from "react"

const Success = (props) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 36 36"
        {...props}
    >
        <title>{"success-standard-line"}</title>
        <path
            className="clr-i-outline clr-i-outline-path-1"
            d="M18 2a16 16 0 1 0 16 16A16 16 0 0 0 18 2Zm0 30a14 14 0 1 1 14-14 14 14 0 0 1-14 14Z"
        />
        <path
            className="clr-i-outline clr-i-outline-path-2"
            d="M28 12.1a1 1 0 0 0-1.41 0l-11.1 11.05-6-6A1 1 0 0 0 8 18.53L15.49 26 28 13.52a1 1 0 0 0 0-1.42Z"
        />
        <path fill="none" d="M0 0h36v36H0z" />
    </svg>
)

export default Success