import React, { useContext } from 'react'
import styles from '../styles/toastPreviewProduct.module.css';
import { AnimatePresence, motion } from 'framer-motion';
import GlobalContext from '../contexts/globalContext';
import { API_URL_IMG, chileanCurrency } from '../services/globals';
import SinImagen from '../img/icon/withoutImage'

const ToastPreviewProduct = () => {

        const { activeToastProduct, dataPreviewProduct } = useContext(GlobalContext)

        return (
                <AnimatePresence>
                        {activeToastProduct && (
                                <motion.div
                                        key="modal_52736"
                                        initial={{ x: 10, opacity: 0 }}
                                        animate={{ x: 0, opacity: 1 }}
                                        exit={{ x: 10, opacity: 0 }}
                                        className={styles.container_toast_preview_product}>
                                        <span>
                                                <p>Producto agregado al carrito</p>
                                        </span>
                                        <span>
                                                {dataPreviewProduct.image_product !== "" && dataPreviewProduct.image_product !== null ? (
                                                        <img src={`${API_URL_IMG}products/${dataPreviewProduct.image_product}`} />
                                                ) : (
                                                        <SinImagen/>
                                                )}
                                                <span>
                                                        <h3 style={{fontSize: dataPreviewProduct.name_product.length > 25 && '0.8em'}}>{dataPreviewProduct?.name_product ?? ""}</h3>
                                                        <h4>cantidad : {dataPreviewProduct?.quantity ?? ""}</h4>
                                                        <p>Valor Unitario $ {chileanCurrency(dataPreviewProduct?.price_product) ?? ""}</p>
                                                </span>
                                        </span>
                                </motion.div>
                        )}
                </AnimatePresence>
        )
}

export default React.memo(ToastPreviewProduct)