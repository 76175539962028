import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import Left from '../../img/icon/left'
import Right from '../../img/icon/right'
import styles from '../../styles/slider.module.css'
import { API_URL_IMG, chileanCurrency } from '../../services/globals'
import usePromotion from '../../hooks/usePromotion'

const Slider = () => {

        const { getAllPromotions, promotion } = usePromotion()
        const [position, setPosition] = useState(0)
        const [box, setBox] = useState([])
        const [[exit, initial, duration], setAnimation] = useState(['0', '0', 0.4])


        useEffect(() => {
                getAllPromotions()

        }, [!promotion])

        const next = () => {
                if (position === promotion.length - 1) {
                        setPosition(0)
                        setAnimation(['-100%', '100%', 0.5])
                        return
                }
                setPosition(position + 1)
                setAnimation(['-100%', '100%', 0.5])
        }

        const prev = () => {
                if (position === 0) {
                        setPosition(promotion.length - 1)
                        setAnimation(['100%', '-100%', 0.5])

                        return
                }
                setPosition(position - 1)
                setAnimation(['100%', '-100%', 0.5])
        }

        useEffect(() => {
                let interval = setInterval(() => {
                        next()
                }, 10000)
                return () => clearInterval(interval)

        }, [position])

        useEffect(() => {
                if (promotion.length > 0) {
                        setBox([promotion[position]])
                }
        }, [position,promotion])

        const handleExitComplete = () => {
                setAnimation(['0', '0', 0.5])
        }

        return (
                <div className={styles.container_slider}>
                        <AnimatePresence exitBeforeEnter onExitComplete={handleExitComplete}>
                                {box.length > 0 && (
                                        box.map((product) => (
                                                <motion.div
                                                        key={product.id}
                                                        initial={{ x: initial, opacity: 0 }}
                                                        animate={{ x: 0, opacity: 1 }}
                                                        exit={{ x: exit, opacity: 0 }}
                                                        transition={{
                                                                opacity: { ease: "easeIn", duration: duration },
                                                                x: { ease: "easeIn", duration: duration },
                                                        }}
                                                        className={styles.box_carrousel}>
                                                        <div>
                                                                <span>
                                                                        <h1>Promoción</h1>
                                                                        <h4>{product.name_promotion}</h4>
                                                                        <h2>$ {chileanCurrency(product.price_promotion)}</h2>
                                                                </span>

                                                        </div>
                                                        <div>
                                                                <img src={`${API_URL_IMG}/products/${product.main_image}`} title={product.main_image} />
                                                        </div>
                                                </motion.div>
                                        ))
                                )}
                        </AnimatePresence>
                        <span className={styles.box_left}>
                                <button type='button' onClick={() => prev()}>
                                        <Left />
                                </button>
                        </span>
                        <span className={styles.box_right}>
                                <button type='button' onClick={() => next()}>
                                        <Right />
                                </button>
                        </span>
                </div>
        )
}

export default Slider