import { AnimatePresence, motion } from 'framer-motion'
import React, { useContext } from 'react'
import GlobalContext from '../../contexts/globalContext';
import ArrowRight from '../../img/icon/arrow-right';
import styles from '../../styles/login.module.css'

const Login = () => {

    const { navigation, submitLogin, session, setActiveLogin, activeLogin,logout } = useContext(GlobalContext);


        const accessMyData =()=>{
            setActiveLogin(false)
            navigation(`mis-datos/cliente`,{replace:true})
        }

        const accessRegister =()=>{
            setActiveLogin(false)
            return navigation('/registro', { replace: true })
        }   

        const accessRecover =()=>{
            setActiveLogin(false)
            return navigation('/recover', { replace: true })
        }
    return (

        <AnimatePresence>
            {activeLogin && (
                session === null ? (
                    <motion.div
                        className={styles.container_login}
                        initial={{ x: 10, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: 10, opacity: 0 }}>
                        <span onClick={() => setActiveLogin(!activeLogin)}>
                            <ArrowRight />
                        </span>
                        <form onSubmit={submitLogin}>
                            <h3>INICIAR SESION</h3>
                            <input type="email" name='email_client' id='email_client' placeholder="Ingresa tu email"/>
                            <input type="password" name='password_client' id='password_client' placeholder="Ingresa tu contraseña"/>
                            <span>
                                <button type="submit">Ingresar</button>
                                <button type="button" onClick={() => accessRegister()}>Registrarse</button>
                            </span>
                            <button type='button' onClick={()=> accessRecover()}>Recuperar contraseña</button>
                        </form>
                    </motion.div>
                ) : (
                    <motion.div
                        className={styles.container_login}
                        initial={{ x: 20, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: 20, opacity: 0 }}>
                        <span onClick={() => setActiveLogin(!activeLogin)}>
                            <ArrowRight />
                        </span>
                        <form>
                            <h3>RESUMEN</h3>
                            <p>{session.name_client}</p>
                            <p>{session.email_client}</p>
                            <p>{session.direction_client}</p>
                            <span>
                                <button type="button" onClick={accessMyData}>Mis datos</button>
                                <button type="button" onClick={logout}>Salir</button>
                            </span>
                        </form>
                    </motion.div>
                )
            )}
        </AnimatePresence>
    )
}

export default Login