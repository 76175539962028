import { API_LOC } from "./globals";

export const DELETE = ({
    table= null,
    id= null,
    name=null,
}) =>
fetch(`${API_LOC}/${table}?id=${id}&name=${name}`,{
    method: 'DELETE',
    redirect: 'follow',
})
.then(response => response.json())
.catch(err => console.log(err))