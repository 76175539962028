import React, { useContext } from 'react'
import GlobalContext from '../contexts/globalContext'
import { POST_LOC } from '../services/POST'

export const useMyData = () => {

        const { setToast, showToast, session, logout } = useContext(GlobalContext)


        const changePassword = (e) => {
                e.preventDefault()
                let data = new FormData(e.target)

                for (let [name, value] of data.entries()) {
                        if (!value) {
                                setToast({
                                        message: `Los campos no pueden estar vacios`,
                                        error: true,
                                        time: 3000
                                })
                                showToast()
                                return;
                        }
                }

                if (data.get('password_client') !== data.get('verification_password')) {
                        setToast({
                                message: `Las contraseñas no coinciden`,
                                error: true,
                                time: 3000
                        })
                        showToast()
                        return;
                }


                data.append('id_client', session.id_client)
                data.delete('verification_password')
                POST_LOC({
                        table: 'clients?reset=true',
                        data: data,
                }).then(response => {
                        if (response.status === 200) {
                                setToast({
                                        message: `Contraseña cambiada con exito`,
                                        error: false,
                                        time: 3000
                                })
                                showToast()
                                logout()

                        } else {
                                setToast({
                                        message: `Error al cambiar la contraseña`,
                                        error: true,
                                        time: 3000
                                })
                                showToast()
                                return;
                        }
                })

        }
        return {
                changePassword
        }
}
