import React, { useContext } from 'react'
import GlobalContext from '../contexts/globalContext'
import styles from '../styles/contactUs.module.css'

const ContactUs = () => {

        const { formContactUs } = useContext(GlobalContext)

        return (
                <div className={styles.container_contact}>
                        <form onSubmit={formContactUs}>
                                <span>
                                        <label htmlFor='name_client'>Nombre</label>
                                        <input type='text' name='name_client' id='name_client' placeholder='Ingresa tu nombre' required />
                                </span>
                                <span>
                                        <label htmlFor='direction_client'>Dirección</label>
                                        <input type='text' name='direction_client' id='direction_client' placeholder='Ingresa tu dirección' required />
                                </span>
                                <span>
                                        <label htmlFor='email_client'>Email</label>
                                        <input type='email' name='email_client' id='email_client' placeholder='Ingresa tu email' required />
                                </span>
                                <span>
                                        <label htmlFor='phone_client'>Telefono</label>
                                        <input type='text' name='phone_client' id='phone_client' placeholder='Ingresa tu telefono' required />
                                </span>
                                <span>
                                        <label htmlFor='message_client'>Mensaje</label>
                                        <textarea name='message_client' id='message_client' placeholder='escribe el mensaje aquí' required></textarea>
                                </span>
                                <span>
                                        <button type='submit'>Enviar</button>
                                </span>
                        </form>
                </div>
        )
}

export default ContactUs