import React from 'react'
import styles from '../styles/aboutUs.module.css';
import logo from '../img/zyro-image.png.jpeg';

const AboutUs = () => {
        return (
                <div className={styles.container_about_us}>
                        <div className={styles.container_logo}>
                                <img src={logo} />
                        </div>
                        <div className={styles.container_content}>
                                <p>Somos una planta Purificadora Juan M.Delmas Radic EIRL, nombre de fantasía AGUADELZAR, es una empresa dedicada a la Producción y Comercialización de productos Elaborados con Agua Altamente purificada,
                                        desmineralizada y Esterilizada. Trabajamos bajo Normas de Calidad ISO 9001-2000, y estamos posicionados en el mercado mas de 13 años en distribución de nuestros productos Recarga de 20 lts. A domicilio,
                                        Hielo Bolsa 1.600 kg. Y Bidón 5 litros desechables</p>
                                <p>Los Objetivos Principales que nos mueven, son la eficiencia en la Calidad de nuestros Productos y excelencia en el Servicio a nuestros Clientes</p>
                                <p>Nuestro Proceso de Elaboración Denominado Purificación de Agua mediante Osmosis Inversa, que se realiza en las siguientes Etapas:</p>
                                <div className={styles.box_list}>
                                        <span>
                                                <strong>Ingreso de Materia Prima</strong>
                                                <p>Agua de la Red con alto índice de contaminación como se muestra en la tabla adjunta</p>
                                        </span>
                                        <span>
                                                <strong>Decantaciones dos estanques y filtraje</strong>
                                                <p> de películas mayores con 5 filtros, de cartucho en fibra de 5 micrones Eliminando el 60 % de sólidos mayores.</p>
                                        </span>
                                        <span>
                                                <strong>Filtro de Osmosis Inversa</strong>
                                                <p>de la presión que permite eliminar un 99% los sólidos disueltos  y 90 % gérmenes y bacterias, con un rendimiento del 55 %,
                                                        ósea de cada 10 litros que ingresan al proceso, se obtienen 5.5 litros de producto AGUADELZAR desmineralizado y 4.5 litros de agua con un alto índice de contaminación.</p>
                                        </span>
                                        <span>
                                                <strong>Filtro de Seguridad y Proceso de Ultravioleta</strong>
                                                <p>Para la eliminación total de Gérmenes y Bacterias obteniendo un producto AGUADELZAR, de primera Calidad, con las características Físico Químicas ,
                                                        Agua altamente desmineralizada que una vez envasado en nuestros botellones previamente lavados , desengrasados, sanitizados  y enjuagados con agua purificada , son almacenados para su distribución.</p>
                                        </span>
                                        <span>
                                                <strong>Eliminación de Cloro mediante filtro de Carbón Activados</strong>
                                                <p>se eliminan los residuos de cloro.</p>
                                        </span>
                                        <span>
                                                <strong>Filtro de Resina Intercambiable</strong>
                                                <p>que absorbe el 30% de los sólidos, minerales existente en el agua.</p>
                                        </span>
                                        <span>
                                                <strong> Filtro de Fibra</strong>
                                                <p>Protección y Eliminación de limpieza del proceso</p>
                                        </span>
                                </div>
                                <div className={styles.box_footer_about}>
                                        <p>Con todo esto obtenemos un producto de excelente calidad, un agua blanda, especial para preparar alimentos, jugos y recomendada médicamente.</p>
                                </div>
                        </div>
                </div>
        )
}

export default AboutUs