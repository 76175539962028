import React, { useContext } from 'react'
import GlobalContext from '../contexts/globalContext'
import User from '../img/icon/user'
import styles from '../styles/myData.module.css'

const MyData = () => {

        const { formMyData, submitMyData } = useContext(GlobalContext)

        if (formMyData === null) return;

        return (
                <div className={styles.container_client}>
                        <form onSubmit={submitMyData}>
                                <div className={styles.box_cart}>
                                        <div>
                                                <span>
                                                        <User />
                                                </span>
                                                <span>
                                                        <p>{formMyData.name_client}</p>
                                                        <p>{formMyData.email_client}</p>
                                                        <p>Cliente : {formMyData.client_type_client === "2" ? "Empresa" : "Personal"}</p>
                                                        <p>Ultima conexion : {formMyData.date_update_client}</p>
                                                </span>
                                        </div>
                                        <div>
                                                <p>Cambiar mis datos</p>
                                                <span>
                                                        <label>Nombre</label>
                                                        <input type='text' name='name_client' placeholder='Ingresa tu nombre' defaultValue={formMyData.name_client} required />
                                                </span>
                                                <span>
                                                        <label>Rut</label>
                                                        <input type='text' name='rut_client' placeholder='Ingresa tu rut' defaultValue={formMyData.rut_client} required />
                                                </span>
                                                <span>
                                                        <label>Telefono</label>
                                                        <input type='text' name='phone_client' placeholder='Ingresa tu telefono' defaultValue={formMyData.phone_client} required />
                                                </span>
                                                <span>
                                                        <label>Direccion</label>
                                                        <input type='text' name='direction_client' placeholder='Ingresa tu direccion' defaultValue={formMyData.direction_client} required />
                                                </span>
                                                <span>
                                                        <label>Referencia</label>
                                                        <input type='text' name='reference_client' placeholder='Datos de referencia' defaultValue={formMyData.reference_client} required />
                                                </span>
                                        </div>

                                </div>
                                <span>
                                        <button type='submit'>Cambiar datos</button>
                                </span>
                        </form>
                </div>
        )
}

export default MyData