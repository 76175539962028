import React, { useContext } from 'react'
import styles from '../styles/shoppingCart.module.css';
import ArrowRight from '../img/icon/arrow-right';
import GlobalContext from '../contexts/globalContext';
import { motion, AnimatePresence } from 'framer-motion';
import SinImagen from '../img/icon/withoutImage'
import LazyImg from './lazyImg';

const ShoppingCart = () => {

        const { activeShopping, setActiveShopping, navigation, clearCart, deleteFromCart, state } = useContext(GlobalContext)
        const { cart, subtotal } = state;

        const goToShoppingCart = () => {

                if (cart.length === 0) {
                        return;
                }

                setActiveShopping(false)
                return navigation('/carro', { replace: true })
        }

        return (
                <AnimatePresence>
                        {activeShopping && (
                                <motion.div
                                        className={styles.container_shopping_cart}
                                        key="modal_12124"
                                        initial={{ x: 10, opacity: 0 }}
                                        animate={{ x: 0, opacity: 1 }}
                                        exit={{ x: 10, opacity: 0 }}>
                                        <span>
                                                <button type='button' onClick={() => setActiveShopping(!activeShopping)}>
                                                        <ArrowRight />
                                                </button>
                                                <p>Carrito</p>
                                        </span>
                                        <div className={styles.box_shopping_cart}>
                                                {cart.map((item, index) => (
                                                        <div key={index} className={styles.card_container}>
                                                                <span>
                                                                        {item.image_product !== "" ? (
                                                                                <LazyImg img={item.image_product} />
                                                                        ) : (
                                                                                <SinImagen />
                                                                        )}
                                                                </span>
                                                                <span>
                                                                        <h3>{item.name_product}</h3>
                                                                        <h3>cantidad :{item.quantity}</h3>
                                                                </span>
                                                                <span>
                                                                        <strong>{Intl.NumberFormat('es-CL').format(parseInt(item.price_product) * parseInt(item.quantity))}</strong>
                                                                </span>
                                                                <button type="button" onClick={() => deleteFromCart(item.id)}>X</button>
                                                        </div>
                                                ))}
                                        </div>
                                        <span>
                                                <strong>Total : {Intl.NumberFormat('es-CL').format(subtotal ?? 0)}</strong>
                                        </span>
                                        <span>
                                                <button type="button" onClick={() => clearCart()}>Limpiar carrito</button>
                                        </span>
                                        <span>
                                                <button type="button" onClick={goToShoppingCart}>Comprar</button>
                                        </span>
                                </motion.div>
                        )}
                </AnimatePresence>
        )
}

export default React.memo(ShoppingCart)