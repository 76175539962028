import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { API_URL_IMG } from '../services/globals';
import styles from '../styles/shoppingCart.module.css'
const LazyImg = ({ img }) => {

        return (
                <LazyLoadImage
                        className={styles.card_container_img_lazy}
                        effect="blur"
                        src={`${API_URL_IMG}products/${img}`}
                        threshold={0}
                        alt="imagen producto"
                />
        )
}


export default LazyImg
