import { createContext, useEffect, useReducer, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TYPES } from "../actions/shoppingAction";
import { shoppingReducer } from "../reducers/shoppingReducer";
import { DELETE } from "../services/DELETE";
import { GET } from "../services/GET";
import { POST, POST_UPLOAD, REGISTER } from "../services/POST";
import { PUT } from "../services/PUT";
import { POST_LOC } from "../services/POST";

const GlobalContext = createContext()

const initialFormRegister = {
        name_client: '',
        email_client: '',
        phone_client: '',
        rut_client: '',
        client_type_client: 1,
        commune_client: '',
        direction_client: '',
        password_client: '',
        password_client_repeat: ''
}

const shoppingInitialState = {
        products: [],
        cart: [],
        subtotat: 0
}

const initialFormMyData = {
        name_client: '',
        email_client: '',
        phone_client: '',
        rut_client: '',
        direction_client: '',
        reference_client: ''
}

const GlobalProvider = ({ children }) => {

        const navigation = useNavigate()
        const { pathname } = useLocation()

        const [session, setSession] = useState(null);
        const [activeShopping, setActiveShopping] = useState(false);
        const [products, setProducts] = useState(null)
        const [promotions, setPromotions] = useState(null)
        const [activeLogin, setActiveLogin] = useState(false);
        const [formRegister, setFormRegister] = useState(initialFormRegister)
        const [activeToast, setActiveToast] = useState(false)
        const [state, dispatch] = useReducer(shoppingReducer, shoppingInitialState)
        const [toggleMenu, setToggleMenu] = useState(false)
        const [myOrders, setMyOrders] = useState(null)
        const [formMyData, setFormMyData] = useState(initialFormMyData)
        const [getIdOrder, setGetIdOrder] = useState(null);
        const [statePay, setStatePay] = useState(false);
        const [idOrder, setIdOrder] = useState(null);
        const [loading, setLoading] = useState(false);
        const [activeToastProduct, setActiveToastProduct] = useState(false);
        const [dataPreviewProduct, setDataPreviewProduct] = useState(null);
        const [toast, setToast] = useState({
                error: false,
                message: 'Sin datos',
                time: 4000,
        })

        const activeIdOrder = (id) => {

                GET({
                        table: 'cart_detail',
                        params: `?order_id=${id}`,
                }).then(response => {
                        if (response.status === 200) {
                                setGetIdOrder(response.results)
                        } else {
                                setGetIdOrder(0)
                        }

                })
        }
        useEffect(() => {

                const verification = JSON.parse(window.localStorage.getItem('data_client'));

                if (verification !== null) {
                        let userTimeExp = parseInt(verification.token_exp_client)
                        let now = Math.floor(Date.now() / 1000);
                        if (userTimeExp > now) {
                                setToast({
                                        message: "Su sesión ha expirado, inicie nuevamente",
                                        error: true,
                                        time: 2000
                                })
                                showToast()
                                logout()
                                return;
                        }

                        //const user = JSON.parse(isLogin)
                        setSession(verification)
                        navigation(pathname, { replace: true })
                }
        }, [])

        const showToast = () => {
                setActiveToast(true)
        }

        const myData = () => {
                if (session !== null) {
                        GET({
                                table: 'clients',
                                params: `?id_client=${session.id_client}`
                        }).then(response => {
                                setFormMyData(response[0])
                        })
                }
        }

        const getAllMyOrders = () => {
                if (session !== null) {
                        GET({
                                table: `buy_order`,
                                params: `?order_client=${session.id_client}`,
                        })
                                .then(response => {

                                        if (response.status === 200) {
                                                setMyOrders(response)
                                        } else {
                                                setMyOrders(0)
                                        }
                                })
                }
        }

        useEffect(() => {
                if (session !== null) {
                        GET({
                                table: `tmp_cart`,
                                params: `?id_client=${session.id_client}`
                        }).then(response => {
                                let sub = response.reduce((acum, value) => parseInt(acum) + parseInt(value.total), 0)
                                state.cart = response
                                state.subtotal = sub
                        })
                }
        }, [session === null])


        const addToCart = (id, price) => {

                if (session === null) {
                        setToast({
                                error: true,
                                message: 'Iniciar session para agregar productos',
                        })
                        showToast()
                        return;
                }

                let verification = state.cart.find(cart => cart.id_product === id);

                if (verification) {
                        updatetmpCart(verification.id)
                        return;
                } else {

                        let data = new URLSearchParams();
                        data.append('id_client', session.id_client)
                        data.append('id_product', id)
                        data.append('quantity', 1)
                        data.append('total', price)

                        POST({
                                table: "tmp_cart",
                                data: data
                        })
                                .then(response => {

                                        setDataPreviewProduct(response[0])
                                        setActiveToastProduct(true)
                                        dispatch({ type: TYPES.ADD_TO_CART, payload: response[0] })
                                })
                }
        }


        const submitRegister = (e) => {
                e.preventDefault()
                let data = new FormData(e.target);

                if (data.get('password_client').trim() === "") {
                        setToast({
                                error: true,
                                message: 'Ingrese una contraseña'
                        })
                        showToast()
                        return;
                }

                POST_LOC({
                        table: "clients?register=true",
                        data: data
                })
                        .then(response => {
                                if (response.status === 200) {
                                        setToast({
                                                error: false,
                                                message: "Registro creado exitosamente"
                                        })
                                        showToast()
                                        navigation('/', { replace: true })
                                } else {
                                        setToast({
                                                error: true,
                                                message: response.results
                                        })
                                        showToast()
                                        return;
                                }
                        })
        }

        const logout = () => {
                setSession(null)
                setActiveLogin(false)
                window.localStorage.removeItem('data_client')
                return navigation('/', { replace: true })
        }

        const updatetmpCart = (idTmp) => {

                PUT({
                        table: "tmp_cart",
                        data: `id=${idTmp}&id_client=${session.id_client}`
                }).then(response => {

                        setDataPreviewProduct(response[0])
                        setActiveToastProduct(true)
                        dispatch({ type: TYPES.ADD_TO_CART, payload: response[0] })
                })
                        .catch(err => console.log(err))
        }

        const submitLogin = (e) => {
                e.preventDefault()

                let data = new FormData(e.target);

                POST_LOC({
                        table: 'clients?login=true',
                        data: data
                }).then(response => {
                        if (response.status === 200) {
                                setToast({
                                        error: false,
                                        message: "iniciando session..."
                                })
                                setSession(response.results[0])
                                window.localStorage.setItem('data_client', JSON.stringify(response.results[0]))
                                setActiveLogin(false)
                                showToast()
                                return;

                        } else if (response.status === 400) {
                                setToast({
                                        error: true,
                                        message: response.results
                                })
                                showToast()
                                return;

                        } else {
                                setToast({
                                        error: true,
                                        message: response.results
                                })
                                showToast()
                                return;
                        }
                })

        }

        const submitMyData = (e) => {
                e.preventDefault()

                let object = Object.fromEntries(new FormData(e.target))
                let p = new URLSearchParams();
                p.append('id_client', formMyData.id_client)
                p.append('name_client', object.name_client)
                p.append('rut_client', object.rut_client)
                p.append('phone_client', object.phone_client)
                p.append('direction_client', object.direction_client)
                p.append('reference_client', object.reference_client)

                PUT({
                        table: 'clients',
                        data: p
                }).then(response => {
                        setToast({
                                error: false,
                                message: "Datos actualizados correctamente"
                        })
                        showToast()
                        myData()
                })
        }

        const deleteFromCart = (id) => {

                DELETE({
                        table: 'tmp_cart',
                        name: 'id',
                        id: id,
                }).then(response => {
                        dispatch({ type: TYPES.REMOVE_ALL_FROM_CART, payload: id })
                })

        }

        const submitOrder = (e) => {
                e.preventDefault()
                setLoading(true)
                let data = new FormData(e.target)
                data.append('id_client', session.id_client)
                POST_LOC({
                        table: 'buy_order?action=create',
                        data: data
                }).then(response => {
                        if (response.status === 200) {

                                if (data.get('payment_method') === "Transferencia") {
                                        navigation(`/detalles/${response.id}`, { replace: true })
                                        return;

                                } else if (data.get('payment_method') === "Getnet") {
                                        let pay = new FormData()
                                        pay.append('id', response.id);
                                        POST_LOC({
                                                table: `payment`,
                                                data: pay
                                        }).then(response => {

                                                if (response.status === 200) {
                                                        window.location.href = response.results
                                                } else {
                                                        return;

                                                }
                                        }).finally(() => setLoading(false))
                                } else {
                                        return;
                                }
                        } else {
                                return;
                        }


                }).finally(() => {
                        clearCart()
                        setLoading(false)
                })
        }

        const clearCart = () => {

                state.cart.map(item => {
                        deleteFromCart(item.id)
                })

                //dispatch({ type: TYPES.REMOVE_ALL_FROM_CART })
        }

        const getProducts = (id) => {
                GET({
                        table: 'products',
                        params: `?client_id=${id}`
                }).then(response => {

                        if (response.status === 200) {
                                setPromotions(response.results.filter(item => item.image_product !== null && item.image_product !== ''))
                                setProducts(response.results)
                        } else {
                                setPromotions(0)
                                setProducts(0)
                        }
                })
        }

        const closeToast = () => {
                setActiveToast(false)
        }

        const closePreviewProduct = () => {
                setActiveToastProduct(false)
        }
        useEffect(() => {
                const interval = setInterval(closeToast, 3000)
                return () => {
                        clearInterval(interval)
                }
        }, [activeToast]);

        useEffect(() => {
                const intervalPreview = setInterval(closePreviewProduct, 3000)
                return () => {
                        clearInterval(intervalPreview)
                }
        }, [activeToastProduct]);

        useEffect(() => {
                if (session !== null) {
                        getProducts(session.id_client)
                } else {
                        getProducts(null)
                }
        }, [session])


        const StatePayment = (id) => {
                setIdOrder(id)
                setStatePay(!statePay);
        }

        const formContactUs = (e) => {
                e.preventDefault()
                let object = Object.fromEntries(new FormData(e.target))

                let data = new URLSearchParams()
                data.append('name_client', object.name_client)
                data.append('email_client', object.email_client)
                data.append('phone_client', object.phone_client)
                data.append('direction_client', object.direction_client)
                data.append('message_client', object.message_client)
                POST({
                        table: 'contact_us',
                        data: data
                }).then(response => {
                        if (response.comment) {
                                setToast({
                                        error: false,
                                        message: "Datos enviados correctamente"
                                })
                                showToast()
                                return navigation('/', { replace: true })
                        } else {
                                setToast({
                                        error: true,
                                        message: "Ups hubo un error , intenta nuevamente"
                                })
                                showToast()
                                return;
                        }
                })

        }

        useEffect(() => {
                myData()
        }, [session === null])

        const payPurchaseOrder = (e) => {
                e.preventDefault()
                let object = Object.fromEntries(new FormData(e.target))
                if (object.voucher_image.name === "") return

                let formData = new FormData()
                formData.append("voucher_image", object.voucher_image, object.voucher_image.name);
                formData.append("id_order", idOrder);

                POST_UPLOAD({
                        table: 'buy_order',
                        data: formData,
                        params: '?upload=true'
                }).then(response => {
                        if (response.status === 200) {
                                getAllMyOrders()
                                setToast({
                                        error: false,
                                        message: "Comprobante guardado correctamente"
                                })
                                showToast()
                                setStatePay(!statePay);
                                return;
                        }
                })
        }

        const data = {
                activeShopping,
                setActiveShopping,
                products,
                activeLogin,
                setActiveLogin,
                session,
                setSession,
                deleteFromCart,
                clearCart,
                navigation,
                submitRegister,
                toast,
                setToast,
                showToast,
                activeToast,
                submitLogin,
                logout,
                state,
                addToCart,
                toggleMenu,
                setToggleMenu,
                submitOrder,
                myOrders,
                formContactUs,
                formMyData,
                submitMyData,
                activeIdOrder,
                getIdOrder,
                payPurchaseOrder,
                statePay,
                setStatePay,
                StatePayment,
                loading,
                getAllMyOrders,
                activeToastProduct,
                setActiveToastProduct,
                dataPreviewProduct,
                setDataPreviewProduct
        };

        return <GlobalContext.Provider value={data}>{children}</GlobalContext.Provider>
}
export { GlobalProvider }
export default GlobalContext;