import * as React from "react"

const WithoutImage = (props) => {


    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 173.15 143.05"
            width="1em"
            height="1em"
            {...props}
        >
            <defs>
                <style>{".cls-1{fill:#c6c6c6}"}</style>
            </defs>
            <g id="Capa_2" data-name="Capa 2">
                <g id="Capa_2-2" data-name="Capa 2">
                    <circle className="cls-1" cx={81.71} cy={39.82} r={12.98} />
                    <path
                        className="cls-1"
                        d="M110.46 10h-9.19a10 10 0 0 0-10-10H72.44a10 10 0 0 0-10 10h-8.75a13.11 13.11 0 0 0-13.12 13.11v34.78A13.11 13.11 0 0 0 53.69 71h56.77a13.11 13.11 0 0 0 13.11-13.11V23.11A13.11 13.11 0 0 0 110.46 10ZM81.57 58.79a19 19 0 1 1 19-19 19 19 0 0 1-19 19Z"
                    />
                    <text
                        transform="translate(0 109.45)"
                        style={{
                            fontFamily: "Arial-Black,Arial",
                            fontWeight: 800,
                            fontSize: "32.7px",
                            fill: "#c6c6c6",
                        }}
                    >
                        {"P"}
                        <tspan
                            x={23.62}
                            y={0}
                            style={{
                                letterSpacing: ".02em",
                            }}
                        >
                            {"r"}
                        </tspan>
                        <tspan x={38.66} y={0}>
                            {"oducto "}
                        </tspan>
                    </text>
                    <text
                        transform="translate(6.05 135.43)"
                        style={{
                            fontSize: "24.87px",
                            fontFamily: "Arial-Black,Arial",
                            fontWeight: 800,
                            fill: "#c6c6c6",
                        }}
                    >
                        {"Sin im"}
                        <tspan
                            x={84.29}
                            y={0}
                            style={{
                                letterSpacing: ".02em",
                            }}
                        >
                            {"a"}
                        </tspan>
                        <tspan x={101.28} y={0}>
                            {"gen"}
                        </tspan>
                    </text>
                </g>
            </g>
        </svg>
    )
}

export default WithoutImage