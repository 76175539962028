import React from 'react'
import styles from '../styles/register.module.css'
import useForm from '../hooks/useForm'

const Register = () => {

        const { submitRegister, loading } = useForm()


        const Loading = () => {
                return (
                        <div className={styles.box_loader}>
                                <div className={styles.loader}></div>
                                <p>Guardando...</p>
                        </div>
                )
        }

        return (
                <div className={styles.container_register}>
                        <form onSubmit={submitRegister} encType="multipart/form-data">

                                <span>
                                        <strong>formulario de registro</strong>
                                </span>
                                <div>
                                        <ul style={{ display: 'flex', flexDirection: 'column', color: 'red', textAlign: 'justify', gap: '10px' }}>
                                                <li><strong>NOTA</strong></li>
                                                <li style={{ color: '#062564' }}>Registrate como Empresa o Distribuidor para acceder a grandes descuentos.</li>
                                                <li>si seleccionas cliente de tipo Empresa o Distribuidor deberas agregar el rut asociado junto con el documento que acredite el rol de empresa </li>
                                        </ul>
                                </div>
                                <span>
                                        <label htmlFor="name_client">Nombre y Apellido</label>
                                        <input type="text" name='name_client' id='name_client' placeholder="Nombre" required />
                                </span>
                                <span>
                                        <label htmlFor="email_client">Email</label>
                                        <input type="email" name='email_client' id='email_client' placeholder="Email" required />
                                </span>
                                <span>
                                        <label htmlFor="rut_client">Rut</label>
                                        <input type="text" name='rut_client' id='rut_client' placeholder="Ingresa tu rut ej: 12456789-2" required />
                                </span>
                                <span>
                                        <label htmlFor="phone_client">Telefono</label>
                                        <input type="text" name='phone_client' id="phone_client" placeholder="Telefono" required/>
                                </span>
                                <span>
                                        <label htmlFor="client_type_client">Tipo de cliente</label>
                                        <select defaultValue='1' name="client_type_client" id="client_type_client" required  >
                                                <option value="1">Personal</option>
                                                <option value="2">Empresa</option>
                                                <option value="3">Distribuidor</option>
                                        </select>
                                </span>
                                <span>
                                        <label htmlFor='direction_client'>Direccion</label>
                                        <input type="text" name='direction_client' id="direction_client" placeholder="Direccion completa" required />
                                </span>
                                <span>
                                        <label htmlFor='document_client'>Documento Empresa/Distribuidor</label>
                                        <input type="file" name='document_client' id="document_client" />
                                </span>
                                <span>
                                        <label htmlFor="password_client">Contraseña</label>
                                        <input type="password" name='password_client' id="password_client" placeholder="Ingresa tu contraseña" required />
                                </span>
                                <span>
                                        <button type="submit" disabled={loading}>
                                                {loading ? <Loading /> : 'Registrarse'}
                                        </button>
                                </span>
                        </form>
                </div>
        )
}

export default Register