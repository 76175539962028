import React from 'react'
import styles from '../styles/footer.module.css'

const Waves = () => {
        return (
                <svg className={styles.waves}
                        viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                        <defs>
                                <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                        </defs>
                        <g className={styles.parallax}>
                                <use href="#gentle-wave" x="48" y="0" fill="rgba(27, 106, 197, 0.6)" />
                                <use href="#gentle-wave" x="48" y="3" fill="rgba(27, 106, 197, 0.6)" />
                                <use href="#gentle-wave" x="48" y="5" fill="rgba(27, 106, 197, 0.6)" />
                        </g>
                </svg>
        )
}

export default Waves