import {  API_LOC } from "./globals";

export const PUT = ({
    table = null,
    data = null
}) =>
    fetch(`${API_LOC}/${table}`, {
        method: 'PUT',
        headers: new Headers({ 'Content-Type': 'application/x-www-form-urlencoded', "Authorization": "d094133a-233b-470b-a214-cac9b134cdce" }),
        body: data,
        redirect: 'follow'
    })
        .then(response => response.json())
        .then(response => response)
        .catch(err => console.log(err))