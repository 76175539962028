import Home from '../pages/home';
import AboutUs from '../pages/aboutUs';
import ContactUs from '../pages/contactUs';
import Register from '../pages/register';
import Cart from '../pages/cart';
import Details from '../pages/details';
import MyData from '../pages/myData';
import HeadeMyData from '../components/headeMyData';
import Orders from '../pages/orders';
import Reset from '../pages/reset';
import OrderDetails from '../pages/orderDetails';
import Recover from '../pages/recover';
import ChangePassword from '../pages/changePassword';
import { createBrowserRouter } from 'react-router-dom';
import NotFound from '../pages/notFound';
import Layout from '../layout/layout';
import Payment from '../pages/payment';

export const router = createBrowserRouter([
        {
                path: '/',
                element: <Layout />,
                errorElement: <NotFound />,
                children: [
                        {
                                index: true,
                                element: <Home />
                        },
                        {
                                path: '/quienes-somos',
                                element: <AboutUs />
                        },
                        {
                                path: '/contactenos',
                                element: <ContactUs />
                        },
                        {
                                path: '/registro',
                                element: <Register />
                        },
                        {
                                path: '/carro',
                                element: <Cart />
                        },
                        {
                                path: '/detalles/:id',
                                element: <Details />
                        },
                        {
                                path: '/mis-datos',
                                element: <MyData />
                        },
                        {
                                path: '/recover',
                                element: <Recover />
                        },
                        {
                                path: '/payment/:id',
                                element: <Payment />
                        },
                        {
                                path: '/recover/:code',
                                element: <ChangePassword />
                        },
                        {
                                path: '/mis-datos',
                                element: <HeadeMyData />,
                                children: [
                                        {
                                                path: '/mis-datos/cliente',
                                                element: <MyData />
                                        },
                                        {
                                                path: '/mis-datos/pedidos',
                                                element: <Orders />
                                        },
                                        {
                                                path: '/mis-datos/pedidos/:id',
                                                element: <OrderDetails />,
                                        },
                                        {
                                                path: '/mis-datos/resetear',
                                                element: <Reset />
                                        }
                                ]
                        }
                ]
        },

])
