export const  API_URL = 'https://apiaguadelzar.aguadelzar.cl'
//export const  API_LOC ='http://localhost/api/cliente'
export const API_LOC ='https://api.aguadelzar.cl/cliente';
export const API_URL_IMG = 'https://api.aguadelzar.cl/administrador/uploads/'
export const chileanCurrency = (number)=> {return Intl.NumberFormat("es-CL").format(number)}
//funciona solo en produccion
export const  HEADERS = new Headers({
        "Authorization":"d094133a-233b-470b-a214-cac9b134cdce"
});

