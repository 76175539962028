import React, { useContext, useState } from 'react'
import styles from '../styles/recover.module.css'
import { POST_LOC } from '../services/POST'
import GlobalContext from '../contexts/globalContext'

const Recover = () => {

        const { setToast, showToast, navigation } = useContext(GlobalContext)
        const [activeOtherSubmit, setActiveOtherSubmit] = useState(false)

        const handleSubmitRecover = (e) => {
                e.preventDefault()
                let data = new FormData(e.target)
                POST_LOC({
                        table: 'clients?recover=true',
                        data: data
                }).then(response => {
                        if (response.status === 200) {
                                setToast({
                                        message: response.results,
                                        error: false,
                                        time: 4000
                                })
                                showToast()
                                setActiveOtherSubmit(true)
                        } else {
                                setToast({
                                        message: response.results,
                                        error: false,
                                        time: 3000
                                })
                                showToast()
                        }
                }).catch(error => console.log(error))
        }

        const handleSubmitCode = (e) => {
                e.preventDefault()
                let data = new FormData(e.target)
                POST_LOC({
                        table: 'clients?reset-password=true',
                        data: data
                }).then(response => {
                        if (response.status === 200) {
                                setToast({
                                        message: response.results,
                                        error: false,
                                        time: 3000
                                })
                                showToast()
                                return navigation(`/recover/${data.get('verification_client')}`, { replace: true })
                        } else {
                                setToast({
                                        message: response.results,
                                        error: false,
                                        time: 3000
                                })
                                showToast()
                        }

                }).catch(error => console.log(error))
        }

        const activeNewForm = () => {
                setActiveOtherSubmit(!activeOtherSubmit)
        }

        return (
                <div className={styles.container_recover}>
                        {activeOtherSubmit ? (
                                <>
                                        <form onSubmit={handleSubmitCode} className={styles.box_form_recover}  >
                                                <input type='number' placeholder='Ingresa el codigo' name='verification_client' required />
                                                <button type='submit'>Enviar</button>
                                        </form>
                                        <span>
                                                <button onClick={() => activeNewForm()}>¿No Tienes el codigo?<strong> Volver</strong></button>
                                        </span>
                                </>
                        ) : (
                                <>
                                        <form className={styles.box_form_recover} onSubmit={handleSubmitRecover}>
                                                <input type="email" name='email_client' id='email_client' placeholder="Ingresa tu email" required />
                                                <button type='submit'>Enviar</button>
                                        </form>
                                        <span>
                                                <button onClick={() => activeNewForm()}>¿Tienes el codigo?<strong> Ingresalo aquí</strong></button>
                                        </span>
                                </>
                        )}
                </div>
        )
}

export default Recover