import React from 'react'
import { Link } from 'react-router-dom'
import Heart from '../img/icon/heart'
import Waves from '../img/waves'
import styles from '../styles/footer.module.css'

const Footer = () => {
        return (
                <div className={styles.container_footer}>
                        <Waves />
                        <div className={styles.box_content_footer}>
                                <span>
                                        <Link to='/'>Inicio</Link>
                                        <Link to='/quienes-somos'>Quienes Somos</Link>
                                        <Link to='/contactenos'>Contactenos</Link>
                                </span>
                                <span>
                                        <p>Sitio web creado con mucho  <Heart /> por <a href='https://bndesigner.com/' target='_blank'>bndesigner.com</a></p>
                                </span>
                        </div>
                </div >
        )
}

export default Footer