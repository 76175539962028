import React, { useContext } from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import GlobalContext from '../contexts/globalContext';
import MyUser from '../img/icon/myUser';
import Shopping from '../img/icon/shopping';
import Unlock from '../img/icon/unlock';
import styles from '../styles/headerMyData.module.css'

const HeadeMyData = () => {

        const {session } = useContext(GlobalContext);

        let activeStyle = {
                color: "#062564",
                border:"1px solid rgba(0,0,0,0.1)",
                boxShadow: "1px 0 4px rgba(0,0,0,0.3)",
                fill: "#062564"
        };

        if(session === null) return;

        return (
                <>
                        <nav className={styles.container_my_data}>
                                <ul>
                                        <NavLink to='/mis-datos/cliente' style={({ isActive }) => isActive ? activeStyle : undefined}>
                                                <span>
                                                        <MyUser />
                                                </span>
                                                <p>Mis datos</p>
                                        </NavLink>
                                        <NavLink to='/mis-datos/pedidos' style={({ isActive }) => isActive ? activeStyle : undefined}>
                                                <span>
                                                        <Shopping />
                                                </span>
                                                <p>Pedidos</p>
                                        </NavLink>
                                        <NavLink to='/mis-datos/resetear' style={({ isActive }) => isActive ? activeStyle : undefined}>
                                                <span>
                                                        <Unlock />
                                                </span>
                                                <p>Cambiar contraseña</p>
                                        </NavLink>
                                </ul>
                        </nav>
                        <Outlet />
                </>
        )
}

export default HeadeMyData