import React, { useContext } from 'react'
import { Link, NavLink } from 'react-router-dom'
import GlobalContext from '../contexts/globalContext';
import ShoppingCard from '../img/icon/cart';
import User from '../img/icon/user';
import styles from '../styles/header.module.css'
import Login from './login/login';
import Logo from '../img/zyro-image.png.jpeg'
import Menu from '../img/icon/menu';
import Close from '../img/icon/close';

const Header = () => {

        const { activeShopping, toggleMenu, setToggleMenu, setActiveShopping, setActiveLogin, session, activeLogin, state } = useContext(GlobalContext);
        const { subtotal } = state;

        let activeStyle = {
                borderTop: '2px solid royalblue',
                borderBottom: ' 2px solid royalblue',
        };

        const activeMenu = () => {
                setActiveShopping(false)
                setToggleMenu(!toggleMenu)
        }

        const showCart = () => {
                setActiveShopping(!activeShopping)
                setActiveLogin(false)
        }

        const showLogin = () => {
                setActiveLogin(!activeLogin)
                setActiveShopping(false)
        }

        return (
                <>
                        <div className={styles.title_header}>
                                <p>2 220405</p>
                                <p>+569 97696294</p>
                                <p>Galvarino Riveros 1136</p>
                        </div>
                        <header className={styles.header}>
                                <button onClick={activeMenu}>
                                        {toggleMenu ? (
                                                <Close />
                                        ) : (
                                                <Menu />
                                        )}
                                </button>
                                <div className={`${toggleMenu ? styles.active_menu : ''}`}>
                                        <Link to="/">
                                                <img src={Logo} title="Logo Aguadelzar" />
                                        </Link>
                                        <nav>
                                                <ul onClick={activeMenu}>
                                                        <li>
                                                                <NavLink to='/' style={({ isActive }) => isActive ? activeStyle : undefined}>Inicio</NavLink>
                                                        </li>
                                                        <li>
                                                                <NavLink to='/quienes-somos' style={({ isActive }) => isActive ? activeStyle : undefined} >Quienes somos</NavLink>
                                                        </li>
                                                        <li>
                                                                <NavLink to='/contactenos' style={({ isActive }) => isActive ? activeStyle : undefined}>Contactenos</NavLink>
                                                        </li>
                                                </ul>
                                        </nav>
                                </div>
                                <button onClick={() => showLogin()}>
                                        {session === null ? (
                                                <>
                                                        <p>Iniciar sesion</p>
                                                        <span>
                                                                <User />
                                                        </span>
                                                </>
                                        ) : (
                                                <>
                                                        <p>Hola {session.name_client.split(' ')[0]}</p>
                                                        <span>
                                                                <b>{session.name_client.split(' ')[0].charAt(0).toUpperCase()}</b>
                                                        </span>
                                                </>
                                        )}
                                </button>
                                <button type="button" onClick={() => showCart()}>
                                        <p>$ {Intl.NumberFormat('es-CL').format(subtotal ? subtotal : 0)}</p>
                                        <span>
                                                <ShoppingCard />
                                        </span>
                                </button>
                        </header>
                        <Login />
                </>
        )
}

export default Header