import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import GlobalContext from '../contexts/globalContext'
import { chileanCurrency } from '../services/globals'
import styles from '../styles/orderDetails.module.css'
import { PUT } from '../services/PUT'

const OrderDetails = () => {

        const { id } = useParams()

        const { getIdOrder, activeIdOrder, myOrders, setToast, showToast } = useContext(GlobalContext)

        const { order, order_details } = getIdOrder !== null && getIdOrder
        useEffect(() => {
                activeIdOrder(id)
        }, [id])

        const orderOrders = (e) => {
                e.preventDefault()

                let order_order = new FormData(e.target)
                if (order_order.get('quantity_to_sent') == 0 || order_order.get('quantity_to_sent') === '') return

                let noPayment = [1, 2, 5]
                if (noPayment.includes(parseInt(order.payment_accepted))) {
                        setToast({
                                message: "Order de compra aun no ha sido pagada",
                                error: true,
                                time: 5000
                        })
                        showToast()
                        return;
                }

                let update = new URLSearchParams()
                update.append('id', order_order.get('id'))
                update.append('quantity_to_sent', order_order.get('quantity_to_sent'))

                PUT({
                        table: 'cart_detail',
                        data: update
                }).then(response => {
                        if (response.status === 200) {
                                activeIdOrder(id)
                        }
                })
        }


        return (
                <div className={styles.container_details_orders}>
                        <table className={styles.box_table_detail}>
                                <caption>Detalles de compra</caption>
                                <thead>
                                        <tr>
                                                <th>Id</th>
                                                <th>Producto</th>
                                                <th>Precio unitario</th>
                                                <th>Cantidad</th>
                                                <th>Preparados para envio</th>
                                                <th>Entregados</th>
                                                <th>Total</th>
                                                <th>Retirar</th>
                                        </tr>
                                </thead>
                                <tbody>
                                        {getIdOrder === null ? (
                                                <tr>
                                                        <td colSpan='4'>Cargando...</td>
                                                </tr>
                                        ) : (
                                                order_details.map(order => (
                                                        <tr key={order.id}>
                                                                <td>{order.id}</td>
                                                                <td>{order.name_product}</td>
                                                                <td>$ {chileanCurrency(order.unit_price)}</td>
                                                                <td>{order.quantity}</td>
                                                                <td>{order.quantity_to_sent}</td>
                                                                <td>{order.delivered}</td>
                                                                <td>$ {chileanCurrency(order.total)}</td>
                                                                <td>
                                                                        {order.manage_shipping === "Manual" && (
                                                                                order.quantity !== order.quantity_to_sent && order.quantity !== order.delivered ? (
                                                                                        <form onSubmit={orderOrders}>
                                                                                                <input type="number" name='quantity_to_sent' min={order.quantity_to_sent} max={(order.quantity - order.quantity_to_sent) - order.delivered} />
                                                                                                <input type="hidden" name='id' value={order.id} />
                                                                                                <button type='submit'>Pedir</button>
                                                                                        </form>
                                                                                ) : (
                                                                                        order.quantity_to_sent != 0 ? (
                                                                                                "En proceso"
                                                                                        ) : (
                                                                                                "Entregado"
                                                                                        )
                                                                                )

                                                                        )}
                                                                </td>
                                                        </tr>
                                                ))
                                        )}
                                </tbody>
                        </table>
                </div>
        )
}

export default OrderDetails
