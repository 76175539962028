import React, { useContext, useState } from 'react'
import { POST_LOC } from '../services/POST'
import GlobalContext from '../contexts/globalContext'

const initialFormRegister = {
        name_client: '',
        email_client: '',
        phone_client: '',
        rut_client: '',
        client_type_client: 1,
        commune_client: '',
        direction_client: '',
        document_client: [],
        password_client: '',
        password_client_repeat: ''
}

const validateForm = (values) => {
        let errors = {}
        if (!values.name_client) {
                errors.name_client = 'El nombre es obligatorio'
        }
        if (!values.email_client) {
                errors.email_client = 'El email es obligatorio'
        } else if (!/\S+@\S+\.\S+/.test(values.email_client)) {
                errors.email_client = 'El email es invalido'
        }
        if (!values.phone_client) {
                errors.phone_client = 'El telefono es obligatorio'
        } else if (!/^[0-9]+$/.test(values.phone_client)) {
                errors.phone_client = 'El telefono es invalido'
        }
        if (!values.rut_client) {
                errors.rut_client = 'El rut es obligatorio'
        } else if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(values.rut_client)) {
                errors.rut_client = 'El rut es invalido'
        }
        if (!values.direction_client) {
                errors.direction_client = 'La direccion es obligatoria'
        }
        if (!values.password_client) {
                errors.password_client = 'La contraseña es obligatoria'
        } else if (values.password_client.length < 6) {
                errors.password_client = 'La contraseña debe tener al menos 6 caracteres'
        }
        if (!values.password_client_repeat) {
                errors.password_client_repeat = 'La contraseña es obligatoria'
        } else if (values.password_client_repeat.length < 6) {
                errors.password_client_repeat = 'La contraseña debe tener al menos 6 caracteres'
        } else if (values.password_client_repeat !== values.password_client) {
                errors.password_client_repeat = 'Las contraseñas no coinciden'
        }
        return errors
}

const useForm = () => {

        const { setToast, showToast, navigation } = useContext(GlobalContext)
        const [loading, setLoading] = useState(false)
        const [errors, setErrors] = useState({})

        const submitRegister = (e) => {
                e.preventDefault()

                let data = new FormData(e.target);
                if (data.get('name_client').trim() === "") {
                        setToast({
                                error: true,
                                message: 'Ingrese su nombre y apellido'
                        })
                        showToast()
                        return;
                }

                if (data.get('email_client').trim() === "") {
                        setToast({
                                error: true,
                                message: 'Ingrese su email'
                        })
                        showToast()
                        return;
                }

                if (data.get('rut_client').trim() === "") {
                        setToast({
                                error: true,
                                message: 'Ingrese su rut de cliente,empresa o distribuidor'
                        })
                        showToast()
                        return;
                }

                if (data.get('phone_client').trim() === "") {
                        setToast({
                                error: true,
                                message: 'Ingrese su numero de telefono'
                        })
                        showToast()
                        return;
                }

                if (parseInt(data.get('client_type_client')) < 1) {
                        setToast({
                                error: true,
                                message: 'Seleccione un tipo de cliente'
                        })
                        showToast()
                        return;
                }

                if (data.get('password_client').trim() === "") {
                        setToast({
                                error: true,
                                message: 'Ingrese una contraseña'
                        })
                        showToast()
                        return;
                }
                
                setLoading(true)

                POST_LOC({
                        table: "clients?register=true",
                        data: data
                })
                        .then(response => {

                                if (response.status === 200) {
                                        setToast({
                                                error: false,
                                                message: "Registro creado exitosamente"
                                        })
                                        showToast()
                                        navigation('/', { replace: true })
                                } else {
                                        setToast({
                                                error: true,
                                                message: response.results
                                        })
                                        showToast()
                                        return;
                                }
                        }).catch(error => {
                                console.log(error)
                        }).finally(() => {
                                setLoading(false)
                        })
        }

        return {
                errors,
                loading,
                submitRegister
        }
}

export default useForm