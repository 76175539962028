import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import GlobalContext from '../contexts/globalContext'
import { POST_LOC } from '../services/POST'
import styles from '../styles/recover.module.css'

const ChangePassword = () => {

        const { setToast, showToast, navigation } = useContext(GlobalContext)
        const { code } = useParams()

        const submitChangePassword = (e) => {
                e.preventDefault()
                let data = new FormData(e.target)
                if (data.get('password_client') !== data.get('password_client2')) {
                        setToast({
                                message: "Las contraseñas no coinciden",
                                error: true,
                                time: 4000
                        })
                        showToast()
                        return
                }

                data.delete('password_client2')

                POST_LOC({
                        table: 'clients?change-password=true',
                        data: data,
                }).then(response => {
                        if (response.status === 200) {
                                setToast({
                                        message: response.results,
                                        error: false,
                                        time: 4000
                                })
                                showToast()
                                return navigation('/', { replace: true });
                        } else {
                                setToast({
                                        message: response.results,
                                        error: true,
                                        time: 4000
                                })
                                showToast()
                                return;
                        }
                })
        }

        return (
                <div className={styles.container_change_password}>
                        <form onSubmit={submitChangePassword}>
                                <h3>Recuperación de contraseña</h3>
                                <input type="hidden" name="verification_client" defaultValue={code} />
                                <input type="password" name="password_client" placeholder="Nueva contraseña" required />
                                <input type="password" name="password_client2" placeholder="Repite la contraseña" required />
                                <button type='submit'>Guardar</button>
                        </form>
                </div>
        )
}

export default ChangePassword