import React from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import { Outlet, useLocation } from 'react-router-dom'
import ShoppingCart from '../components/shoppingCart'
import Toast from '../components/toast'
import { GlobalProvider } from '../contexts/globalContext'
import ToastPreviewProduct from '../components/toastPreviewProduct'

const Layout = () => {

        let {pathname} = useLocation()
        let path = "mis-datos"

        return (
                <GlobalProvider>
                        <Header />
                        <main>
                                <Outlet />
                        </main>
                        <ShoppingCart />
                        <ToastPreviewProduct/>
                        <Toast />
                        {pathname.indexOf(path) == -1 ? <Footer /> : null
                        }

                </GlobalProvider>
        )
}

export default Layout