import React, { useState } from 'react'
import { GET } from '../services/GET'

const usePromotion = () => {

        const [promotion, setPromotion] = useState([])


        const getAllPromotions = () => {
                GET({
                        table: 'promotions',
                        params: '?list=all'
                }).then(response => {
                        if (response.status === 200) {
                                setPromotion(response.results)
                        } else {
                                setPromotion(0)
                        }
                })
        }

        return {
                getAllPromotions,
                promotion
        }

}

export default usePromotion