import React, { useContext, useEffect } from 'react'
import CartPayment from '../components/orders/CartPayment';
import GlobalContext from '../contexts/globalContext'
import { chileanCurrency } from '../services/globals';
import styles from '../styles/orders.module.css'

const Orders = () => {

        const { myOrders, navigation, StatePayment, statePay ,getAllMyOrders} = useContext(GlobalContext);

        const accessOrderDetails = (id) => {
                return navigation(`/mis-datos/pedidos/${id}`, { replace: true })
        }

        useEffect(() => {
                getAllMyOrders()
        }, [])

        return (
                <div className={styles.container_orders}>
                        <h3>Mis pedidos ({myOrders !== null ? myOrders.total : 0})</h3>
                        <div>
                                <table className={styles.box_order_table}>
                                        <thead>
                                                <tr>
                                                        <th>id</th>
                                                        <th>metodo de pago</th>
                                                        <th>estado pago</th>
                                                        <th>telefono cliente</th>
                                                        <th>Cantidad productos</th>
                                                        <th>Detalles de envio</th>
                                                        <th>total de la compra</th>
                                                        <th>acciones</th>
                                                </tr>
                                        </thead>
                                        <tbody>
                                                {myOrders === null ? (
                                                        <tr>
                                                                <td colSpan='4'>Cargando...</td>
                                                        </tr>
                                                ) : (
                                                        myOrders === 0 ? (
                                                                <tr>
                                                                        <td colSpan='4'>No</td>
                                                                        <td colSpan='4'>No tienes</td>
                                                                        <td colSpan='4'> pedidos activos</td>
                                                                </tr>
                                                        ) : (
                                                                myOrders.orders.map((item, index) => (
                                                                        <tr key={index}>
                                                                                <td>{item.id}</td>
                                                                                <td>{item.payment_method}</td>
                                                                                <td>{item.payment_accepted == 1 ?
                                                                                        <button type='button' style={{ minWidth: '100px' }} onClick={() => StatePayment(item.id)}>Pagar</button> : item.name_payment}</td>
                                                                                <td>{item.phone_client}</td>
                                                                                <td>{item.quantity_total}</td>
                                                                                <td>
                                                                                        {item.quantity_to_sent === 0 && item.in_transit === 0 && item.delivered === 0 && (
                                                                                                <p style={{ background: 'red' }}>Procesando</p>
                                                                                        )}
                                                                                        {item.quantity_total !== item.delivered ? (
                                                                                                <>
                                                                                                        {item.quantity_to_sent > 0 && (
                                                                                                                <p style={{ background: 'gold' }}>{item.quantity_to_sent} Pendientes de envio</p>
                                                                                                        )}
                                                                                                        {item.in_transit > 0 && (
                                                                                                                <p style={{ background: 'orange' }}>{item.in_transit} En transito</p>
                                                                                                        )}
                                                                                                        {item.delivered > 0 && (
                                                                                                                <p style={{ background: 'green' }}>{item.delivered} Entregado</p>
                                                                                                        )}
                                                                                                </>
                                                                                        ) : (
                                                                                                <p style={{ background: 'green' }}>Todos Entregados</p>
                                                                                        )}
                                                                                </td>
                                                                                <td>$ {chileanCurrency(item.total_to_pay)}</td>
                                                                                <td>
                                                                                        <button style={{ backgroundColor: item.manage_shipping === 'Manual' ? '#248CA9' : '#57BA6F', color: '#FFFFFF' }} type='button' onClick={() => accessOrderDetails(item.id)}>{item.manage_shipping === 'Manual' ? 'Gestionar' : 'Detalles'}</button>
                                                                                </td>
                                                                        </tr>
                                                                ))
                                                        )
                                                )}
                                        </tbody>
                                </table>
                        </div>
                        {statePay && (
                                <CartPayment />
                        )}
                </div >
        )
}

export default Orders