import React from 'react'
import styles from '../styles/reset.module.css'
import { useMyData } from '../hooks/useMyData'

const Reset = () => {

        const { changePassword } = useMyData()
        return (
                <div className={styles.container_reset}>
                        <div className={styles.container_note}>
                                <h2><strong>NOTA:</strong></h2>
                                <ul>
                                        <li>Deberas iniciar sesion nuevamente</li>
                                </ul>
                        </div>
                        <div className={styles.container_change_password}>
                                <form onSubmit={changePassword}>
                                        <h4>Recuperación de contraseña</h4>
                                        <input type='password' name='password_client' placeholder='Nueva contraseña'  required/>
                                        <input type='password' name='verification_password' placeholder='Repita nueva contraseña'  required/>
                                        <button type='submit'>Guardar</button>
                                </form>
                        </div>
                </div>
        )
}

export default Reset