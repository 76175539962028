import React from 'react'
import Slider from '../components/home/slider'
import Products from '../components/products'
import styles from '../styles/home.module.css'
import logo from '../img/zyro-image.png.jpeg'

const Home = () => {

        return (
                <section className={styles.container_home}>
                        <div className={styles.container_logo}>
                                <img src={logo} />
                        </div>
                        <Slider />
                        <Products />
                </section>
        )
}

export default React.memo(Home)