import * as React from "react"

const Exclamation = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 36 36"
    {...props}
  >
    <title>{"error-standard-line"}</title>
    <circle
      className="clr-i-outline clr-i-outline-path-1"
      cx={18}
      cy={26.06}
      r={1.33}
    />
    <path
      className="clr-i-outline clr-i-outline-path-2"
      d="M18 22.61a1 1 0 0 1-1-1v-12a1 1 0 1 1 2 0v12a1 1 0 0 1-1 1Z"
    />
    <path
      className="clr-i-outline clr-i-outline-path-3"
      d="M18 34a16 16 0 1 1 16-16 16 16 0 0 1-16 16Zm0-30a14 14 0 1 0 14 14A14 14 0 0 0 18 4Z"
    />
    <path fill="none" d="M0 0h36v36H0z" />
  </svg>
)

export default Exclamation
