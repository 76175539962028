import { API_LOC, HEADERS } from "./globals";

export const GET = ({
    table = null,
    params = ""
}) =>
    fetch(`${API_LOC}/${table}${params}`, {
        method: 'GET',
    })
        .then(response => response.json())
        .then(response => response)
        .catch(err => console.error("Error de servidor code:", err))