import { TYPES } from "../actions/shoppingAction"

export const shoppingReducer = (state, action) => {

        switch (action.type) {
                case TYPES.ADD_TO_CART: {

                        let verificationItem = state.cart.find(cart => cart.id === action.payload.id)

                        let newCart = verificationItem
                                ? state.cart.map(item => {
                                        return item.id === action.payload.id ? action.payload : item;
                                })
                                : [...state.cart, { ...action.payload }]

                        return {
                                ...state,
                                cart: newCart,
                                subtotal: newCart.reduce((acum, value) => parseInt(acum) + parseInt(value.total), 0)
                        }
                }

                case TYPES.REMOVE_ONE_FROM_CART: {


                }

                case TYPES.REMOVE_ALL_FROM_CART: {

                        let newCart = state.cart.filter(item => parseInt(item.id) !== parseInt(action.payload))

                        return {
                                ...state,
                                cart: newCart,
                                subtotal: newCart.reduce((acum, value) => parseInt(acum) + parseInt(value.total), 0)
                        }
                }
                        break;
                case TYPES.CLEAR_CART: {

                }
                default:
                        return state;

        }
}