import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import GlobalContext from '../contexts/globalContext'
import SinImagen from '../img/icon/withoutImage'
import { GET } from '../services/GET'
import styles from '../styles/cart.module.css'
import payGetnet from '../img/payment_getnet.svg'
import transferencia from '../img/ico-app-santander.svg'
import { AnimatePresence, motion } from 'framer-motion'

const Cart = () => {

        const { state, session, submitOrder, navigation, loading } = useContext(GlobalContext);
        const [client, setClient] = useState(null)
        const { cart, subtotal } = state;

        const getClient = () => {
                if (cart.length === 0) {
                        return navigation('/', { replace: true })
                }

                if (session !== null) {
                        GET({
                                table: 'clients',
                                params: `?id_client=${session.id_client}`
                        }).then(response => setClient(response[0]))
                } else {
                        return navigation('/', { replace: true })
                }
        }

        useEffect(() => {
                getClient()
        }, [session == null])

        const validationButton = () => {
                cart.length === 0 && navigation('/', { replace: true })
        }

        return (
                <article className={styles.container_cart}>
                        <form className={styles.container_form} onSubmit={submitOrder}>
                                {client !== null && (

                                        <div className={styles.box_inputs}>
                                                <span>
                                                        <h3>Datos dirección</h3>
                                                </span>
                                                <span>
                                                        <label htmlFor='name_client'>Nombres</label>
                                                        <input id='name_client' type="text" name='name_client' defaultValue={client.name_client} required />
                                                </span>
                                                <span>
                                                        <label htmlFor='rut_client'>Rut</label>
                                                        <input id='rut_client' type="text" name='rut_client' defaultValue={client.rut_client} required />
                                                </span>
                                                <span>
                                                        <label htmlFor='email_client'>Email</label>
                                                        <input id='email_client' type="email" name='email_client' defaultValue={client.email_client} required readOnly />
                                                </span>
                                                <span>
                                                        <label htmlFor='direction_client'>Direccion</label>
                                                        <input id='direction_client' type="text" name='direction_client' defaultValue={client.direction_client} required />
                                                </span>
                                                <span>
                                                        <label htmlFor='phone_client'>Telefono</label>
                                                        <input id='phone_client' type="text" name='phone_client' defaultValue={client.phone_client} required />
                                                </span>
                                                <span>
                                                        <label htmlFor='additional_comments'>Comentarios adicionales</label>
                                                        <input id='additional_comments' type="text" name='additional_comments' />
                                                </span>
                                                <span>
                                                        <label htmlFor='manage_shipping'>Gestionar envio</label>
                                                        <select name='manage_shipping' id='manage_shipping'>
                                                                <option defaultValue value='Automatico'>Apenas el pago se realice</option>
                                                                <option value='Manual'>Gestionar manualmente (Mis datos)</option>
                                                        </select>
                                                </span>
                                        </div>
                                )}
                                <div className={styles.box_shopping_cart}>
                                        <h3>Resumen de tus productos</h3>
                                        <div>
                                                {cart.map((item, index) => (
                                                        <div key={index} className={styles.box_resume_cart}>
                                                                <span>
                                                                        {item.image_product !== "" ? (
                                                                                <img width='50px' src={`https://apiaguadelzar.aguadelzar.cl/uploads/${item.image_product}`} />
                                                                        ) : (
                                                                                <SinImagen />
                                                                        )}
                                                                </span>
                                                                <span>
                                                                        <h3>{item.name_product}</h3>
                                                                        <h3>cantidad :{item.quantity}</h3>
                                                                </span>
                                                                <span>
                                                                        <strong>$ {Intl.NumberFormat('es-CL').format(parseInt(item.price_product) * parseInt(item.quantity))}</strong>
                                                                </span>
                                                        </div>
                                                ))}
                                                <span className={styles.box_method_payments}>
                                                        <p>Selecciona el metodo de pago</p>
                                                        <span>
                                                                <input type="radio" name='payment_method' value="Getnet" id='Getnet' defaultChecked />
                                                                <label htmlFor='Getnet'>
                                                                        <img src={payGetnet} />
                                                                        <p>Credito/Debito</p>
                                                                </label>
                                                        </span>
                                                        <span>
                                                                <input type="radio" name='payment_method' value="Transferencia" id='transferencia' />
                                                                <label htmlFor='transferencia'>
                                                                        <img src={transferencia} />
                                                                        <p>Transferencia</p>
                                                                </label>
                                                        </span>
                                                </span>
                                                <span className={styles.box_total}>
                                                        <h3>Total : $ {Intl.NumberFormat('es-CL').format(subtotal)}</h3>
                                                </span>
                                                <span>
                                                        <button type="submit" onClick={()=> validationButton()}>Pagar</button>
                                                </span>
                                        </div>
                                </div>
                        </form>
                        <AnimatePresence>
                                {loading && (
                                        <motion.div className={styles.loading} >
                                                <span></span>
                                                <p>Cargando pasarela de pago</p>
                                        </motion.div>
                                )}
                        </AnimatePresence>
                </article>
        )
}

export default Cart