import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { useContext } from 'react'
import GlobalContext from '../contexts/globalContext'
import Exclamation from '../img/icon/exclamation'
import Success from '../img/icon/success'
import styles from '../styles/toast.module.css'

const Toast = () => {
    const { toast, activeToast } = useContext(GlobalContext)

    return (
        <AnimatePresence>
            {activeToast &&
                <motion.div
                    className={styles.container_toast}
                    initial={{ x: 0, opacity: 0 }}
                    animate={{ x: -10, opacity: 1 }}
                    exit={{ x: 0, opacity: 0 }}>
                    <span style={{ backgroundImage: toast.error ? 'red' : 'linear-gradient(to right, #062564, #02478B)'}}>
                        {toast.error ? (
                            <Exclamation />
                        ) : (
                            <Success />
                        )}
                    </span>
                    <span>
                        <p>{toast.message}</p>
                    </span>
                </motion.div>
            }
        </AnimatePresence>
    )
}

export default Toast