import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import styles from '../styles/payment.module.css'
import { GET } from '../services/GET';

const Payment = () => {

        const [order, setOrder] = useState([])
        const [status, setStatus] = useState([])
        const { id } = useParams();

        const getOrder = () => {
                GET({
                        table: 'buy_order',
                        params: `?a_order=${id}`
                }).then(response => {
                        setOrder(response[0])

                        GET({
                                table: `payment`,
                                params: `?request_id=${response[0].transaction_id}`
                        }).then(response => {
                                setStatus(response)
                        })
                }).catch(error => console.log(error))
        }

        useEffect(() => {
                getOrder()
        }, [id])

        return (
                <div className={styles.container_payment_getnet}>
                        <div className={status.status === 400 || status.status === 405 ? styles.box_shadow_error : styles.box_shadow}>
                                {status.status === 400 && (
                                        <>
                                                <p>Hola <strong>{order.name_client}</strong></p>
                                                <p>{status.results.message}</p>
                                                <p>Puedes intentarlo mas tarde</p>
                                        </>
                                )}
                                {status.status === 200 && (
                                        <>
                                                <p>Hola <strong>{order.name_client}</strong> Muchas Gracias por tu compra, Tu pedido N° {id} se ha pagado exitosamente.</p>
                                                <p>En breve nos pondremos en contacto contigo</p>
                                                <p>Ademas podras ver todo el proceso de envio en la session MIS DATOS <strong>(Pedidos)</strong></p>
                                        </>
                                )}
                                {status.status === 405 && (
                                        <>
                                                <p>Hola <strong>{order.name_client}</strong></p>
                                                <p>{status.results}</p>
                                                <p>Puedes intentarlo mas tarde</p>
                                        </>
                                )}
                        </div>
                </div>
        )
}

export default Payment