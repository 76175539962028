import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import CartPayment from '../components/orders/CartPayment'
import GlobalContext from '../contexts/globalContext'
import { GET } from '../services/GET'
import styles from '../styles/details.module.css'

const Details = () => {

        const { id } = useParams()
        const { session, navigation, setStatePay, statePay,StatePayment } = useContext(GlobalContext);
        const [buyOrder, setBuyOrder] = useState(null)
        const [details, setDetails] = useState(null)

        const getBuyOrder = () => {
                GET({
                        table: 'buy_order',
                        params: `?id=${id}`
                }).then(response => {
                        if (response.buy_order !== 0) {

                                setBuyOrder(response.buy_order[0])
                                setDetails(response.cart_details)
                        } else {
                                navigation('/', { replace: true })
                        }
                })
        }


        useEffect(() => {
                getBuyOrder()
        }, [session === null])

        return (
                <div className={styles.container_global_details}>
                        {buyOrder === null ? (
                                <div>loader</div>
                        ) : (
                                <>
                                        <div className={styles.container_table_pay}>
                                                <table>
                                                        <caption>Detalle de compra</caption>
                                                        <thead>
                                                                <tr>
                                                                        <th>Imagen</th>
                                                                        <th>Nombre</th>
                                                                        <th>Cantidad</th>
                                                                        <th>Subtotal</th>
                                                                </tr>
                                                        </thead>
                                                        <tbody>
                                                                {details.map(item => (
                                                                        <tr key={item.id}>
                                                                                <td>sin imagen</td>
                                                                                <td>{item.name_product}</td>
                                                                                <td>{item.quantity}</td>
                                                                                <td>$ {Intl.NumberFormat('es-CL').format(item.total)}</td>
                                                                        </tr>
                                                                ))}
                                                        </tbody>
                                                        <tfoot>
                                                                <tr>
                                                                        <td>Total</td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td colSpan='1'>$ {Intl.NumberFormat('es-CL').format(buyOrder.total_to_pay)}</td>
                                                                </tr>
                                                        </tfoot>
                                                </table>
                                        </div>
                                        <div className={styles.box_content_payment}>
                                                <li>Para completar tu compra es necesario que puedas transferir a la brevedad el monto Total</li>
                                                <li>Ademas deberas subir el comprobante de tu compra en la seccion MIS DATOS</li>
                                                <li>Podras ver todo el proceso de tu pedido en la seccion MIS DATOS</li>
                                                <li>mas abajo encontraras los datos de tranferencia para finalizar tu compra</li>
                                                <li>Muchas gracias por comprar en Aguadelzar.cl</li>
                                        </div>
                                        <div className={styles.container_payment_cart}>
                                                <button type='button' onClick={()=> StatePayment(id)}>Pagar y/o subir comprobante</button>
                                        </div>
                                </>
                        )}
                        {statePay && (
                                <CartPayment />

                        )}
                </div>
        )
}

export default React.memo(Details)