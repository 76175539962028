import React, { useContext } from 'react'
import GlobalContext from '../contexts/globalContext'
import styles from '../styles/productsHome.module.css'
import SinImagen from '../img/icon/withoutImage'
import { API_URL_IMG, chileanCurrency } from '../services/globals'

const Products = () => {

        const { products, addToCart } = useContext(GlobalContext)

        if (products === null) return;
        
        return (
                <article className={styles.container_products}>
                        <h3>NUESTROS PRODUCTOS</h3>
                        {products === null ? (
                                <p>Cargando...</p>
                        ) : (
                                products === 0 ? (
                                        <p>Sin productos</p>
                                ) : (
                                        products.map((product, index) => (
                                                <div key={index} className={styles.box_product}>
                                                        <span>
                                                                {product.image_product === "" || product.image_product === null ? (
                                                                        <SinImagen />
                                                                ) : (
                                                                        <img src={`${API_URL_IMG}products/${product.image_product}`} />
                                                                )}
                                                        </span>
                                                        <span>
                                                                <h3>{product.name_product}</h3>
                                                                <p>$ {chileanCurrency(product.price_product)}</p>
                                                                <span>
                                                                        <button type='button' onClick={() => addToCart(product.id_product, product.price_product)}>Agregar</button>
                                                                </span>
                                                        </span>
                                                </div>
                                        ))
                                )
                        )}

                </article>
        )
}

export default Products